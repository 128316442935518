* {
    -webkit-overflow-scrolling: touch
}

html, body, h1, h2, h3, h4, h5, h6, div, dl, dt, dd, ul, ol, li, p, blockquote, pre, hr, figure, table, caption, th, td, form, fieldset, legend, input, button, textarea, menu {
    padding: 0;
    margin: 0;
}

header, footer, section, article, aside, nav, hgroup, address, figure, figcaption, menu, details {
    display: block;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

caption, th {
    font-weight: normal;
    text-align: left;
}

html, body, fieldset, img, iframe, abbr {
    border: 0;
}

i, cite, em, var, address, dfn {
    font-style: normal;
}

[hidefocus], summary {
    outline: 0;
}

li {
    list-style: none;
}

h1, h2, h3, h4, h5, h6, small {
    font-size: 100%;
}

sup, sub {
    font-size: 83%;
}

pre, code, kbd, samp {
    font-family: inherit;
}

q::before, q::after {
    content: none;
}

textarea {
    overflow: auto;
    resize: none;
}

label, summary {
    cursor: default;
}

a, button {
    cursor: pointer;
}

h1, h2, h3, h4, h5, h6, em, strong, b {
    font-weight: bold;
}

body, textarea, input, button, select, keygen, legend {
    font-size: 14px;
    color: #333;
    outline: 0;
}

body {
    background: #fff;
}

a, a:hover {
    color: #333;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input {
    background-color: transparent;
    border: none;
    border-radius: 0; /* 解决ios上输入框圆角问题 */
    outline: medium; /* 去掉鼠标点击的默认黄色边框 */
    -moz-appearance: none;
    -webkit-appearance: none; /* 解决ios上按钮的圆角问题 */
}
