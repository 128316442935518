@font-face {
  font-family: 'Roboto-Regular';
  src: url("../font/Roboto-Regular.ttf");
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../font/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../font/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../font/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url('../font/Roboto-Thin.ttf');
}

@font-face {
  font-family: 'RobotoMono-Regular';
  src: url('../font/RobotoMono-Regular.ttf');
}

@font-face {
  font-family: 'RobotoMono-Bold';
  src: url('../font/RobotoMono-Bold.ttf');
}

@font-face {
  font-family: 'RobotoCondensed-Regular';
  src: url('../font/RobotoCondensed-Regular.ttf');
}

html, body, #root {
  box-sizing: border-box;
  height: 100%;
  font-size: 14px;
}

body {
  font-family: Roboto-Regular, RobotoMono-Regular, "Microsoft YaHei", 微软雅黑, "Microsoft JhengHei", 华文细黑, STHeiti, MingLiu, serif;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.ai {
  align-content: flex-start;
  align-items: flex-start;
}

.js {
  justify-content: flex-start;
}

.sp {
  justify-content: space-between;
}

.je {
  justify-content: flex-end;
}

.fw {
  flex-wrap: wrap;
}

.cur {
  cursor: pointer;
}

.mh {
  height: 100%;
}

.mantle {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  transition: 300ms;
  animation: 300ms 0ms 1 showMantle;
  //pointer-events: none;
}

@keyframes showMantle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
